<template>
  <div class="pay">
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />

    <div v-if="types == 1">
      <div class="top">
        <div>申报职业</div>
        <div>{{ goodsname }}</div>
      </div>
      <div class="tops">
        <van-icon name="records" /><span
          style="font-size: 22px; padding-top: -10px"
          >订单信息</span
        >
      </div>
      <div class="aaa">
        <div>姓名</div>
        <div>{{ name }}</div>
      </div>
      <div class="aaa">
        <div>身份证号</div>
        <div>{{ idcardno }}</div>
      </div>
      <div class="aaa">
        <div>申报职业</div>
        <div>{{ goodsname }}</div>
      </div>
      <div class="aaa">
        <div></div>
        <div>
          报名费<span style="font-size: 22px; color: red; font-weight: 700"
            >￥{{ pardes.price }}元</span
          >
        </div>
      </div>
      <div class="bot">
        <div>
          合计：<span style="font-size: 24px; color: red; font-weight: 700"
            >￥{{ pardes.price }}</span
          >
        </div>
        <div @click="pays">立即支付</div>
      </div>
    </div>

    <div v-else>
      <div class="top">
        <div>石家庄</div>
        <div>灵活就业养老医疗</div>
      </div>
      <div class="tops">
        <van-icon name="records" /><span
          style="font-size: 22px; padding-top: -10px"
          >订单信息</span
        >
      </div>
      <div class="aaa">
        <div>姓名</div>
        <div>{{ data.true_name }}</div>
      </div>
      <div class="aaa">
        <div>身份证号</div>
        <div>{{ data.true_num }}</div>
      </div>
      <div class="aaa">
        <div>业务类型</div>
        <div>{{ data.order_type == 1 ? "养老业务 " : "养老医疗" }}</div>
      </div>
      <div class="aaa">
        <div></div>
        <div>
          服务费<span style="font-size: 22px; color: red; font-weight: 700"
            >￥{{ data.payble_money }}元</span
          >
        </div>
      </div>
      <div class="bot">
        <div>
          合计：<span>￥{{ data.payble_money }}</span>
        </div>
        <button @click="pay">立即支付</button>
      </div>
    </div>
  </div>
</template>

<script>
import titleNav from "@/components/conetnts/titleNav.vue";
import { Toast, Icon, Dialog } from "vant";
import wxLogin from "@/assets/js/code.js";
import {
  detail,
  pay,
  orderpay,
  getskillgoodslevelfind,
} from "@/network/index.js";
export default {
  components: {
    titleNav,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
  },
  data() {
    return {
      data: {},
      name: "",
      idcardno: "",
      goodsname: "",
      id: "",
      pardes: {},
      types: 0,
    };
  },
  mounted() {
    this.types = this.$route.query.types;
    this.id = this.$route.query.data;
    // if (/MicroMessenger/.test(window.navigator.userAgent)) {
    //   const wxcode = wxLogin.getCode2("code");
    //   if (!wxcode) {
    //     wxLogin.getUserCode("http://lhjy.sjzydrj.net/pay");
    //   } else {
    //     // this.codeSplicet(wxcode);
    //   }
    // }
    if (this.$route.query.types == 1) {
      this.orderadd();
      // this.pays();
    } else {
      this.details();
    }
  },
  methods: {
    // 获取code
    codeSplicet() {},
    // login
    details() {
      let id = sessionStorage.getItem("id");
      let payid = sessionStorage.getItem("payId");
      // let data = {
      //   id: '34ce4daae6b79e8055bf22a653186a59',
      //   userid: '3658603',
      // };
      let data = {
        id: payid,
        // id: this.id,
        userid: id,
      };
      detail(data).then((res) => {
        this.data = res.data.data;
        // let path = this.$route.path; //先获取路由路径
        // this.$router.push(path); //再跳转路由路径，query参数没带过去，所以被清除了
        // alert(window.location.href);
      });
    },
    pay() {
      // Dialog.alert({
      //   title: "支付功能维护中",
      //   message:
      //     "可拨打人工客服及400电话[400-8877-827] \n 接听时间为工作日09:00~17:00",
      // }).then(() => {});
      // return;
      var _this = this;
      let id = sessionStorage.getItem("id");
      let payid = sessionStorage.getItem("payId");
      let data = {
        // id: this.id,
        id: payid,
        userid: id,
      };
      pay(data).then((res) => {
        console.log("=======res.data.code========",res.data.code);
        if (res.data.code == 200) {
          let data = res.data.data.jsApiParameters;
          // alert(JSON.stringify(data))
          WeixinJSBridge.invoke(
            "getBrandWCPayRequest",
            {
              appId: data.appId,
              paySign: data.paySign,
              timeStamp: data.timeStamp,
              nonceStr: data.nonceStr,
              package: data.package,
              signType: data.signType,
            },
            (res) => {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                Toast("支付成功");
                sessionStorage.setItem("add", "");
                if (_this.$route.query.identification == 1) {
                  _this.$router.go(-4);
                } else {
                  _this.$router.go(-3);
                }
              } else {
                Toast.fail("支付失败");
              }
            }
          );
        } else {
          Toast.fail("支付失败,请重新支付");
        }
      });
    },
    // 存储的数据
    orderadd() {
      let orderadd = JSON.parse(sessionStorage.getItem("orderadd"));
      console.log(orderadd);
      if (
        orderadd !== null &&
        orderadd !== "" &&
        orderadd !== undefined &&
        orderadd !== "null"
      ) {
        this.name = orderadd.name;
        this.idcardno = orderadd.idcardno;
        this.goodsid = orderadd.goodsid;
        this.goodsname = orderadd.goodsname;
        let userid = sessionStorage.getItem("id");
        let data = {
          userid,
          goodsid: orderadd.goodsid,
          levelname: orderadd.levelname,
        };
        getskillgoodslevelfind(data).then((res) => {
          this.pardes = res.data.data;
        });
      }
    },
    pays() {
      var _this = this;
      let userid = sessionStorage.getItem("id");
      let data = {
        orderid: this.$route.query.orderid,
        userid,
      };
      orderpay(data).then((res) => {
        console.log(res);
        if (res.data.code) {
          // sessionStorage.setItem("id", "undefined")
          // window.location.href =res.data.data
          // this.$router.push("/index");
          window.location.replace(res.data.data);
        } else {
          Toast(res.data.msg);
        }
        // let data = res.data.data.jsApiParameters;
        // WeixinJSBridge.invoke(
        //   "getBrandWCPayRequest",
        //   {
        //     appId: data.appId,
        //     paySign: data.paySign,
        //     timeStamp: data.timeStamp,
        //     nonceStr: data.nonceStr,
        //     package: data.package,
        //     signType: data.signType,
        //   },
        //   (res) => {
        //     console.log(res);
        //     if (res.err_msg == "get_brand_wcpay_request:ok") {
        //       Toast("支付成功");
        //       sessionStorage.setItem("orderadd", "");
        //       if(_this.$route.query.id==1){
        //         _this.$router.go(-3);
        //       }else{
        //         _this.$router.go(-2);
        //       }

        //     } else {
        //       //   that.$router.push({ path: "/myOrder" });
        //       Toast.fail("支付失败");
        //       if(_this.$route.query.id==1){
        //         _this.$router.go(-3);
        //       }else{
        //         _this.$router.go(-2);
        //       }
        //     }
        //   }
        // );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.pay {
  .top {
    background-color: rgb(15, 128, 254);
    width: 100vw;
    height: 150px;
    color: #ffffff;
    div:nth-child(1) {
      font-size: 22px;
      font-weight: 700;
      margin-left: 15px;
      line-height: 50px;
      padding-top: 30px;
    }
    div:nth-child(2) {
      font-size: 20px;
      margin-left: 15px;
    }
  }
  .tops {
    margin-left: 15px;
    font-size: 30px;
    line-height: 80px;
    padding-top: 10px;
    display: flex;
    align-items: center;
  }
  .aaa {
    margin: 0 25px;
    font-size: 20px;
    height: 40px;
    div:nth-child(1) {
      float: left;
    }
    div:nth-child(2) {
      float: right;
    }
  }
  .bot {
    font-size: 22px;
    position: fixed;
    bottom: 0;
    width: 100vw;
    height: 80px;
    border-top: 1px solid #eee;
    display: flex;
    justify-content: space-between;
    div {
      // display: inline-block;
    }
    div:nth-child(1) {
      line-height: 80px;
      // width: 60vw;
      padding-left: 20px;
      background-color: #ffffff;
      font-size: 20px;
      span {
        font-size: 25px;
        color: red;
        font-weight: 700;
      }
    }
    button {
      width: 140px;
      line-height: 80px;
      color: #ffffff;
      text-align: center;
      background-color: rgb(15, 128, 254);
      border: 0;
      // font-size: 50px;
    }
  }
}
</style>
